import { useStaticQuery, graphql } from 'gatsby'
// No variables permitted in static Queries ... YET!
export const useSiteSource = (graphQLEndPoint = true) => {
  const {
    wp: {
      allSettings: { generalSettingsUrl: sourceUrl },
    },
  } = useStaticQuery(
    graphql`
      query SiteSourceQuery {
        wp {
          allSettings {
            generalSettingsUrl
          }
        }
      }
    `
  )

  return graphQLEndPoint ? `${sourceUrl}/graphql` : sourceUrl
}
